<template>
  <router-view />
</template>


<style lang="scss">
$border: 2px solid rgba($color: black,
    $alpha: 0.35,
  );
$size1: 6px;
$size2: 12px;
$size3: 18px;
$size4: 24px;
$size5: 48px;
$backgroundColor: #e3e3e3;
$textColor: black;
$primaryColor: #F26B22;
$secondaryColor: #ff975f;
$secondTextColor: black;

* {
  transition: 200ms;
}
html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-size: 12pt;
  line-height: 1.3;

  #app {
    max-width: 1200px;
    margin: 0 auto;
    min-height: 100vh;

    .namecard-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 16px;
      padding: 16px;

      @media screen and (min-width: 1024px) {
        grid-template-columns: 1fr 1fr 1fr;
        min-height: calc(100vh - 32px);
      }
    }

    .namecard-form {
      @media screen and (min-width: 1024px) {
        padding-right: 16px;
        border-right: $border
      }
    }

    .namecard-list {
      @media screen and (min-width: 1024px) {
        grid-column-start: 2 span;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      width: 100%;

      label {
        font-size: 14px;
        font-weight: bold;
      }

      input[type="text"],
      button {
        height: $size5;
        box-shadow: none;
        outline: none;
        padding-left: $size2;
        padding-right: $size2;
        border-radius: $size1;
        font-size: 18px;
        margin-top: $size1;
        margin-bottom: $size2;
      }

      input[type="text"] {
        background-color: white;
        border: none;
        filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.5));
        color: inherit;
      }
    }

    button {
      cursor: pointer;
      background-color: $primaryColor;
      border: 1px solid $primaryColor;
      color: $secondTextColor;
      font-weight: bold;
      outline: none;
      border-radius: $size1;
      transition: 200ms;
      filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.5));

      &:disabled {
        background: grey;
        border: 1px solid grey;
        cursor: default;
      }

      &.secondary {
        background-color: $secondaryColor;
        border: 1px solid $secondaryColor;

        &:disabled {
          background: grey;
          border: 1px solid grey;
        }
      }
    }

    ul {
      padding: 0;

      .namecard-list-item {
        display: grid;
        grid-template-columns: 32px 1fr 1fr 1fr 1fr;
        column-gap: 8px;
        row-gap: 8px;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid $primaryColor;
        padding: $size2 $size4;
        border-radius: $size1;
        margin-bottom: $size2;
        background-color: white;
        filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.5));

        strong {
          display: block;
        }

        button {
          font-size: $size2;
          padding: $size1;
          grid-column-start: 5;
        }

        ul {
          list-style-type: none;
          display: flex;
          column-gap: 6px;
          row-gap: 6px;
          flex-wrap: wrap;

          img {
            width: 16px;
          }
        }
      }
    }
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.333);
  z-index: 1;
}

.overlay-container {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  left: 0;

  .overlay-container-wrapper {
    background: white;
    padding: 16px;
    border-radius: 8px;
  }
}

.error {
  color: red;
  font-weight: 900;
  margin-bottom: 12px;
}

table {
  border-spacing: 0;
  margin-bottom: 12px;

  thead {
    th {
      background: lightgrey;
      font-weight: 900;
    }
  }

  tbody {
    tr:nth-child(even) {
      th {
        background: #e3e3e3;
      }
    }

    th {
      font-weight: normal;
    }
  }
}

th {
  padding: 4px 16px;
  border-collapse: unset;
}
</style>