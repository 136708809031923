
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router'

export default {
  name: 'App',
  setup() {
    const nameNamecards = ref('');
    const errorName = ref("");
    const validateInput = () => {
      errorName.value = nameNamecards.value === "" ? "The Name field is required" : "";
    };
    const nicknameNamecards = ref(null);
    const titleNamecards = ref(null);
    const langNameCards = ref([
      {
        lang: 'NL',
        value: true,
      },
      {
        lang: 'EN',
        value: true,
      },
      {
        lang: 'GE',
        value: false,
      },
      {
        lang: 'FR',
        value: false,
      },
      {
        lang: 'SP',
        value: false,
      },
      {
        lang: 'IT',
        value: false,
      },
    ]);
    const namecardsData = JSON.parse(localStorage.getItem('namecards')!) || [];
    const fillEmptySpaceData = JSON.parse(localStorage.getItem('fillEmptySpace')!) !== null ? JSON.parse(localStorage.getItem('fillEmptySpace')!) : true;
    const namecards = ref(namecardsData);
    const fillEmptySpace = ref(fillEmptySpaceData);
    let clipboardtable = ref([['']]);
    let overlayActive = ref(false);
    const errorClipboard = ref("");
    const isValidClipboard = ref(true);
    const validateInputClipboard = () => {
      errorClipboard.value = isValidClipboard.value ? "The Name field is required" : "";
    };

    const router = useRouter()
    const route = useRoute()

    function generateCards() {
      router.push({
        name: 'print',
        query: {
          ...route.query,
        },
      })
    }

    function addListOfNamecards() {
      clipboardtable.value.forEach(namecard => {
        namecards.value.push({
          name: namecard[0],
          nickname: namecard[1],
          title: namecard[2],
          lang: [
            {
              lang: 'NL',
              value: namecard[3] === "TRUE" ? true : false,
            },
            {
              lang: 'EN',
              value: namecard[4] === "TRUE" ? true : false,
            },
            {
              lang: 'GE',
              value: namecard[5] === "TRUE" ? true : false,
            },
            {
              lang: 'FR',
              value: namecard[6] === "TRUE" ? true : false,
            },
            {
              lang: 'SP',
              value: namecard[7] === "TRUE" ? true : false,
            },
          ]
        });
      });
      overlayActive.value = false;
      clipboardtable.value = [['']]
      saveData();
    }

    function addNamecards() {
      if (nameNamecards.value === '') return validateInput()
      namecards.value.push({
        name: nameNamecards.value,
        nickname: nicknameNamecards.value,
        title: titleNamecards.value,
        lang: [...langNameCards.value].slice(),
      });
      nameNamecards.value = '';
      nicknameNamecards.value = null;
      titleNamecards.value = null;
      langNameCards.value = [...langNameCards.value],
        saveData();
    }
    function removeNamecards(index: number) {
      namecards.value.splice(index, 1);
      saveData();
    }
    function saveData() {
      const storageData = JSON.stringify(namecards.value);
      localStorage.setItem('namecards', storageData);
    }
    function savefillEmptySpaceData() {
      const storageData = JSON.stringify(fillEmptySpace.value);
      localStorage.setItem('fillEmptySpace', storageData);
    }
    function changeLang() {
      const inputs = document.querySelectorAll(".change-lang-input")
      langNameCards.value = [
        {
          lang: 'NL',
          value: false,
        },
        {
          lang: 'EN',
          value: false,
        },
        {
          lang: 'GE',
          value: false,
        },
        {
          lang: 'FR',
          value: false,
        },
        {
          lang: 'SP',
          value: false,
        },
        {
          lang: 'IT',
          value: false,
        },
      ]
      inputs.forEach(input => {
        if ((input as any).checked) {
          langNameCards.value.forEach(item => {
            if (item.lang === (input as any).id.split('changeLang')[1]) item.value = true
          })
        }
      })
    }

    function getImgUrl(lang: string) {
      return require('../assets/flags/' + lang + '.png')
    }

    async function getClipboardData() {
      var clipText = await navigator.clipboard.readText();
      const errorText = "Your clipboard needs to contain contents of a table"
      if (typeof clipText !== 'string') return errorClipboard.value = errorText
      if (clipText === '') return errorClipboard.value = errorText
      if (!/[\t]/g.test(clipText)) return errorClipboard.value = errorText
      clipboardtable.value = clipText.split('\n').map((line) => line.split('\t').map(i => i.replace('\r', '')))
      overlayActive.value = true;
      errorClipboard.value = '';
    }

    return {
      generateCards,
      namecards,
      errorName,
      validateInput,
      nameNamecards,
      fillEmptySpace,
      nicknameNamecards,
      titleNamecards,
      langNameCards,
      addListOfNamecards,
      addNamecards,
      removeNamecards,
      saveData,
      savefillEmptySpaceData,
      changeLang,
      getClipboardData,
      clipboardtable,
      overlayActive,
      errorClipboard,
      getImgUrl
    }
  }
}
